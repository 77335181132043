import Layout from '@/components/layout';
import React from 'react';

export default function Weather() {
  return (
    <Layout>
      <h1 className="py-8 text-4xl font-bold md:text-5xl text-center">
        Weather Data
      </h1>

      <div className="container prose pb-48">
        <p>
          Got an idea for a class, a teacher you think we should connect with, or anything else you want to discuss?
        </p>

        <iframe width="500" height="400" src="https://ambientweather.net/devices/public/d9199f18b2e124a2dca48036d30e3229/graphs?embed=true"></iframe><p>powered by <a target="_blank" href="https://ambientweather.net">Ambient Weather</a></p>
      </div>
    </Layout>
  );
}
